<template>
  <a-layout id="components-layout-demo-custom-trigger">
   <a-layout-header style="height:64px; padding: 0;display: flex">
     <!--      <a-icon-->
     <!--          class="trigger"-->
     <!--          :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
     <!--          @click="() => (collapsed = !collapsed)"-->
     <!--      />-->
     <div class="logo" >
       <span @click="()=>{this.$router.push('/liveRoot')}" style="cursor: pointer">
         <img  style="width:170px;" src="../../../assets/yunicu-logo.png" alt="">
       </span>
     </div>
     <a-menu
         theme="dark"
         mode="horizontal"
         :default-selected-keys="['liveRoot']"
         :style="{ lineHeight: '64px' }"
     >
       <a-menu-item key="/" >
         <router-link to="/">
           社区运营管理后台
         </router-link>
       </a-menu-item>
       <a-menu-item key="liveRoot">
         <router-link to="/liveRoot">
           直播运营管理后台
         </router-link>
       </a-menu-item>
       <!-- <a-menu-item key="caseRoot">
         <router-link to="/caseRoot">
           病例收集管理后台
         </router-link>
       </a-menu-item> -->
       <a-menu-item key="SubmissionProjectManagement" @click="go_ResearchSubmission">
            调研投稿管理后台
       </a-menu-item> 
       <a-menu-item key="https://yun-manage.yunicu.com/appData/appHomepage" @click="go_appData">
         数据分析管理后台
       </a-menu-item>
     </a-menu>
     <ManageHeader></ManageHeader>
   </a-layout-header>

 <a-layout>
   <a-layout-sider
       :trigger="null"
       collapsible
       breakpoint="xl"
       class="sider"
   >
    <a-menu theme="dark" mode="inline"
             v-model=select
             :default-selected-keys="select"
     >
     <!-- <a-sub-menu key="sub1">
       <span slot="title">
         <img src="../../../assets//直播图标//工作台.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">平台运营分析管理</span></span>
       <a-menu-item key="1">
        <router-link to="/liveRoot">
           <span>工作台(运营经理/主管/专员)</span>
        </router-link>
       </a-menu-item>
     </a-sub-menu> -->

     <a-sub-menu key="sub2">
       <span slot="title">
         <img src="../../../assets/直播图标/直播会议管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">直播会议管理</span></span>
       <a-menu-item key="sub2-1">
        <router-link to="/liveConferenceOverview"><span>直播会议总览</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub2-2">
        <router-link to="/liveliveMeet"><span>直播会议管理</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub2-3">
        <router-link to="/liveliveactive"><span>直播间抽奖活动管理</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub2-4">
        <router-link to="/liveliveAnswers"><span>直播间问答管理</span></router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub3">
       <span slot="title">
         <img src="../../../assets/直播图标/医.png" style="width:28px;height:25p;margin-left:-15px">
         <span  style="margin-left:5px">医学会议管理</span></span>
       <a-menu-item key="sub3-1">
           <router-link to="/livetemplateAddress"><span>首页模板地址管理</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub3-2">
           <router-link to="/livefunctionTypes"> <span>首页功能类型管理</span></router-link> 
       </a-menu-item>
       <a-menu-item key="sub3-3">
           <router-link to="/livecommonFunctions"> <span> 首页常用功能管理 </span></router-link>
       </a-menu-item>
       <a-menu-item key="sub3-4">
           <router-link to="/livehomepageTemplate"> <span>首页模板管理</span></router-link>        
       </a-menu-item>
       <a-menu-item key="sub3-5">
           <router-link to="/livemeetingManagement"><span>会议管理</span></router-link>   
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub4">
       <span slot="title">
         <img src="../../../assets/直播图标/直播评分管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">直播评分管理管理</span>
       </span>
       <a-menu-item key="sub4-1">
           <router-link to="/livesupervisorRating"><sapn>主管评分界面</sapn></router-link>
       </a-menu-item>
       <a-menu-item key="sub4-2">
           <router-link to="/livesalesRating"><span>销售评分界面</span></router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub5">
       <span slot="title">
         <img src="../../../assets/直播图标/点播视频管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">点播视频管理</span></span>
       <a-menu-item key="sub5-1">
           <router-link to="/livevideoOnDemand"><span>点播管理 </span></router-link> 
       </a-menu-item>
       <a-menu-item key="sub5-2">
           <router-link  to="/livevideoLibrary">  <span>视频库管理</span> </router-link>
       </a-menu-item>
       <a-menu-item key="sub5-3">
           <router-link to="/liveopeningCredits"> <span> 视频片头片尾管理</span> </router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub6">
       <span slot="title">
         <img src="../../../assets/直播图标/会议会诊管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">会诊会议管理管理</span></span>
       <a-menu-item key="sub6-1">
           <router-link to="/liveexpertsMeet"><span>平台专家管理</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub6-2">
           <router-link to="/livezoomMeet"><span>zoom会议ID管理</span></router-link>
       </a-menu-item>
        <a-menu-item key="sub6-3">
         <router-link to="/liveconsultationMeets"><span>会诊会议管理</span></router-link>
       </a-menu-item> 
       <a-menu-item key="sub6-4">
       会诊会议ID管理
       </a-menu-item>
       <a-menu-item key="sub6-5">
        直播ID管理
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub7">
       <span slot="title">
         <img src="../../../assets/直播图标/banner广告管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">Banner广告管理</span></span>
       <a-menu-item key="sub7-1">
       <router-link to="/livehomeBannerManagement"><span>Banner广告管理</span></router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub8">
       <span slot="title">
         <img src="../../../assets/直播图标/客户管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">客户管理</span></span>
       <a-menu-item key="sub8-1">
         <router-link to="/livesponsorManagement"><span> 直播主办方管理</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub8-2">
         <router-link to="/liveprogressQuery"><span>直播计划进展查询</span></router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub9">
       <span slot="title">
         <img src="../../../assets/直播图标/直播数据字典.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">直播数据字典管理</span></span>
       <a-menu-item key="sub9-1">
           <router-link to="/livelivedatadictionary"><span>会议直播</span></router-link>
       </a-menu-item>
       <a-menu-item key="sub9-2">
           <router-link to="/livelivedesigndictionary"> <span>会议设计</span></router-link>
       </a-menu-item>
     </a-sub-menu>

     <a-sub-menu key="sub10">
       <span slot="title">
         <img src="../../../assets/直播图标/模板管理.png" style="width:28px;height:25p;margin-left:-15px">
         <span style="margin-left:5px">模板管理</span></span>
       <a-menu-item key="sub10-1">
       <router-link to="/livetemplatemanage"><span>模板管理</span></router-link>
       </a-menu-item>
     </a-sub-menu>
     </a-menu> 
   </a-layout-sider>
   <a-layout-content
       :style="{ margin: '15px 16px', padding: '15px', background: '#fff', minHeight: '280px',
           overflowY:'scroll'}"
   >
     <router-view style="overflow-y: hidden"></router-view>
   </a-layout-content>

 </a-layout>
 </a-layout>
</template>

<script>
import ManageSider from "@/components/ManageSider";
import ManageHeader from "@/components/ManageHeader";
import ManageFooter from "@/components/ManageFooter";
export default {
  name:'liveRoot',
  components: {ManageFooter, ManageHeader, ManageSider},
  data(){
   return{
     select:['1'],
   }
 },
 watch:{
   $route(to,from){
     if (to.path==='/liveliveRoot'){
       this.select=['1']
     }
   }
 },
 mounted() {
   const user_info = window.localStorage.getItem("user_info");
   this.userInfo =  JSON.parse(user_info)
 },
 methods:{
   go_appData(){
     // const url = "https://yun-manage.yunicu.com/appData/appHomepage?byId="+this.userInfo.id
     const url = "https://yun-manage.yunicu.com/appData/appHomepage"
     location.href=url
   },
   go_ResearchSubmission(){
     const url = "https://yun-manage.yunicu.com/case/admin/"
     location.href=url
   },
 }
}
</script>

<style>

</style>